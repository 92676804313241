import React, { useState, useEffect, useRef } from 'react'
import { Storage } from 'aws-amplify'

import AssetThumbnail from '../components/thumbnail'
import Layout from 'gatsby-theme-awesome-docs/src/components/layout'
import './uploads.scss'

const UploadForm = () => {
  const fileUploadRef = useRef()
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [uploadCount, setUploadCount] = useState(1)
  const [imageList, setImageList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [selectedTab, setSelectedTab] = useState('images')

  const chooseFileUpload = e => {
    setSelectedFiles(e.target.files)
  }

  const addNewFileToList = async (section, fileName) => {
    const newFile = await Storage.list(fileName)
    section === 'image'
      ? setImageList([...imageList, ...newFile])
      : setVideoList([...videoList, ...newFile])
  }

  const onSubmitForm = async e => {
    e.preventDefault()
    setIsUploading(true)
    for (let file of selectedFiles) {
      const { name, type } = file
      const section = type.split('/')[0]
      const fileName = `${section}/${name
        .trim()
        .replaceAll(/\s+/g, '_')
        .toLocaleLowerCase()}`

      try {
        await Storage.put(fileName, file, {
          contentType: type,
          // progressCallback(progress) {
          //   console.log(progress)
          // },
        });
        // await new Promise(res => setTimeout(() => res(), 1000))
        setUploadCount(count => count + 1)
        addNewFileToList(section, fileName)
      } catch (error) {
        console.log('Error uploading file: ', error)
      }
    }
    setIsUploading(false)
    setSelectedFiles(null)
    setUploadCount(0)
    fileUploadRef.current.value = ''
  }

  const getFileList = async section => {
    try {
      const files = await Storage.list(section)
      section === 'image' ? setImageList(files) : setVideoList(files)
    } catch (error) {
      console.log('Error', error)
    }
  }

  useEffect(() => {
    getFileList('image')
    getFileList('video')
  }, [])

  return (
    <Layout>
      <div className='uploads-container'>
        <h1>Uploads</h1>
        <p>
          Upload videos or images from your computer to use it in the
          documentation.
        </p>
        <form onSubmit={onSubmitForm}>
          <input
            ref={fileUploadRef}
            type='file'
            className='file-upload'
            onChange={chooseFileUpload}
            disabled={isUploading}
            multiple={true}
            required
          />
          <input
            type='submit'
            value={
              isUploading
                ? `Uploading... (${uploadCount}/${selectedFiles.length})`
                : 'Upload'
            }
            disabled={isUploading || !selectedFiles}
          />
        </form>
        <button
          value='images'
          onClick={() => setSelectedTab('images')}
          className={selectedTab === 'images' ? 'active' : ''}
        >
          <h2>Images</h2>
        </button>
        <button
          value='images'
          onClick={() => setSelectedTab('videos')}
          className={selectedTab === 'videos' ? 'active' : ''}
        >
          <h2>Videos</h2>
        </button>
        {selectedTab === 'images' && (
          <div>
            {imageList?.length === 0 ? (
              <p>No images have been uploaded yet.</p>
            ) : (
              <p>Click on a file name below to copy the image/video URL.</p>
            )}
            {imageList?.length > 0 &&
              imageList.map((file, index) => {
                return (
                  <AssetThumbnail
                    key={file.key}
                    assetKey={file.key}
                    index={index}
                    type='image'
                  />
                )
              })}
          </div>
        )}
        {selectedTab === 'videos' && (
          <div>
            {videoList?.length === 0 ? (
              <p>No videos have been uploaded yet.</p>
            ) : (
              <p>Click on a file name below to copy the video URL.</p>
            )}
            {videoList?.length > 0 &&
              videoList.map((file, index) => {
                return (
                  <AssetThumbnail
                    key={file.key}
                    assetKey={file.key}
                    index={index}
                    type='video'
                  />
                )
              })}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default UploadForm
