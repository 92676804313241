import React, { useState, useEffect, Fragment } from 'react'
import { Storage } from 'aws-amplify'

const AssetThumbnail = ({ assetKey, type }) => {
  const [showDeleteAsset, setShowDeleteAsset] = useState(false)
  const [showFeedback, setShowFeedback] = useState('')

  const cdnUrl = `${process.env.GATSBY_CDN_DOMAIN_NAME}/public/`

  useEffect(() => {
    if (showFeedback.length > 0) setTimeout(() => setShowFeedback(''), 3000)
  }, [showFeedback])

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(`${cdnUrl}${assetKey}`)
    setShowFeedback('Copied to clipboard!')
  }

  // const onClickDelete = () => setShowDeleteAsset(true)
  // TODO: Add delete functionality
  const onClickClose = () => setShowDeleteAsset(false)

  const deleteAsset = async () => {
    await Storage.remove(assetKey);
    setShowDeleteAsset(false)
    setShowFeedback('Asset deleted')
  }

  return (
    <Fragment>
      {type === 'image' && (
        <div key={assetKey} className='asset' style={{ backgroundImage: `url(${cdnUrl}${assetKey})`, backgroundSize: 'cover' }}>
          <button title='Copy link' className='copy-image'
            onClick={copyToClipboard}
          >{showFeedback.length > 0 ? showFeedback : assetKey?.slice(6)}</button>
          {/*        <button 
          key='delete' 
          className='delete-asset'
          onClick={onClickDelete}
        >
          x
        </button>*/}
        </div>
      )}
      {type === 'video' && (
        <div key={assetKey} className='asset' style={{ width: '12rem', display: 'inline-block', height: '9rem', overflow: 'hidden', marginRight: '1rem' }}>
          <video src={`${cdnUrl}${assetKey}`} style={{ height: '100%', zIndex: '1' }} />
          <button
            key='name'
            className='copy-video'
            onClick={copyToClipboard}
          >{showFeedback.length > 0 ? showFeedback : assetKey?.slice(6)}</button>
          {/*        <button 
          key='delete' 
          className='delete-asset'
          onClick={onClickDelete}
        >
          x
        </button>*/}
        </div>
      )}
      {showDeleteAsset && (
        <div className='modal'>
          <div>
            <h1>Delete asset</h1>
            <p>Do you want to permanently delete {assetKey.slice(6)}?</p>
            <button className='standard' onClick={deleteAsset} style={{ color: '#FFF', backgroundColor: '#bc1616' }}>Delete</button>
            <button className='standard' onClick={onClickClose}>Cancel</button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AssetThumbnail